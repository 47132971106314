import React from 'react';
import { Spoiler, Table } from '@mantine/core';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/2024/luth-ground-round/1.jpeg';
import blogd2 from '../../assets/images/2024/luth-ground-round/2.jpeg';
import blogd3 from '../../assets/images/2024/luth-ground-round/3.jpeg';
import blogd4 from '../../assets/images/2024/luth-ground-round/4.jpeg';
import blogd5 from '../../assets/images/2024/luth-ground-round/5.jpeg';
import blogd6 from '../../assets/images/2024/luth-ground-round/6.jpeg';
import blogd7 from '../../assets/images/2024/luth-ground-round/7.jpeg';
import blogd8 from '../../assets/images/2024/luth-ground-round/8.jpeg';
import blogd9 from '../../assets/images/2024/luth-ground-round/9.jpeg';
import blogd10 from '../../assets/images/2024/luth-ground-round/11.jpeg';
import blogd11 from '../../assets/images/2024/luth-ground-round/10.jpeg';
import blogd12 from '../../assets/images/2024/luth-ground-round/12.jpeg';
import blogd13 from '../../assets/images/2024/luth-ground-round/13.jpeg';

import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  const subs = [
    {
      SNO: '1',
      PRESENTERS: 'DR. A.K Toye',
      SESSION: 'CASE 1',
    },
    {
      SNO: '2',
      PRESENTERS: 'Dr. O.D Tom- AYEGUNLE',
      SESSION: 'CASE 2',
    },
    {
      SNO: '3',
      PRESENTERS: 'DR. T. AYO- Olagunju',
      SESSION: 'Timing of initiation of Dialysis AKI',
    },
    {
      SNO: '4',
      PRESENTERS: 'Dr. D. Ezuduemoih',
      SESSION: 'Timing of initiation of Dialysis CKD',
    },
    {
      SNO: '2',
      PRESENTERS: 'Dr. B. T. BELLO/ Dr. R. W. BRAIMOH',
      SESSION: 'Overview',
    },
  ];

  const subRows = subs.map((element) => (
    <tr key={element.SNO}>
      <td>{element.SNO}</td>
      <td>{element.PRESENTERS}</td>
      <td>{element.SESSION}</td>
    </tr>
  ));

  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={blogd1} alt='' />
              </div>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  LUTH HOSPITAL GRAND ROUND: TO DIALYZE OR NOT TO DIALYZE, WHAT
                  IS THE EVIDENCE?
                </h2>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  The first Lagos University Teaching Hospital Grand round for
                  the year 2024 began with a notable start with the theme: “To
                  dialyze or not to dialyze, what is the evidence? “
                  <br />
                  <Table fontSize='md' striped>
                    <thead>
                      <tr>
                        <th>SNO</th>
                        <th>PRESENTERS</th>
                        <th>SESSION</th>
                      </tr>
                    </thead>
                    <tbody>{subRows}</tbody>
                  </Table>
                  An Overview of the presentation was done by Dr. Bello Babawale
                  Taslim Associate professor: Honorary Consultant Nephrologist
                  department of medicine, CMUL/ LUTH
                  <br />
                  Dr. Bello addressed the misconceptions regarding Timing of
                  initiation of dialysis in patients with renal failure.
                  <br />
                  He emphasized the effect on the outcomes of kidney failure of
                  actions such as addressing the underlying cause and
                  correctable abnormalities
                  <br />
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd2} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd3} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* Consultative Services Ongoing at the Outreach */}
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Dr. Bello also used the medium to discourage colleagues from
                  prejudicing the minds of patients and their relatives with
                  regards to the need for dialysis, they should await nephrology
                  review before commenting on whether dialysis is indicated and
                  also refrain from comments that may suggest that the
                  nephrology unit is unwilling to dialyze any patient.
                  <br />
                  The special presentation which was done by Dr. Varun Bansal
                  (heart Surgeon, Cardio- thoracic and Vascular Surgeon) spoke
                  about “saving yourself from the fear of heart diseases- past
                  vs future” He spoke on how heart surgery has improved over the
                  years.
                </p>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd4} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* attendees at the seminar */}
                </span>

                {/* <table style='width:100%'>
                  <tr>
                    <th>Date</th>
                    <th>Activities</th>
                    <th>Venue</th>
                  </tr>
                  <tr>
                    <td>Tuesday, 1st August</td>
                    <td>Webinar</td>
                    <td>@3:00pm</td>
                  </tr>
                  <tr>
                    <td>Wednesday, 2nd August, 2023</td>
                    <td>Awareness Creation in LUTH
                        <br />
                        Presentation by NANPAN LUTH @ Nurse's Seminar Room
                    </td>
                    <td>@ 8:30am
                        <br />
                        @11:00am
                    </td>
                  </tr>
                  <tr>
                    <td>Thursday, 3rd August</td>
                    <td>Train the trainers on BFHI @ Paediatrics Department</td>
                    <td>@9:00am - 2:00pm</td>
                  </tr>
                  <tr>
                    <td>Friday, 4th August</td>
                    <td>Outreach Program at Seriki's Compound, Haruna Street, Idi-Araba</td>
                    <td>@9:00am - 12:00noon</td>
                  </tr>
                  <tr>
                    <td>Friday, 4th August</td>
                    <td>Outreach Program at Seriki's Compound, Haruna Street, Idi-Araba</td>
                    <td>@1:00pm</td>
                  </tr>
                  <tr>
                    <td>Sunday, 6th August</td>
                    <td>Health Talk @ LUTH/CMUL Chaplaincy, Idi-Araba</td>
                    <td>@9:30am</td>
                  </tr>
                </table> */}
                {/* <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  She said that NSOANP had to do with providing affordable,
                  accessible and safe surgery to Nigerians that need it as part
                  of Universal Health Coverage. “This is as a result of World
                  Health Assembly Resolution in 2015 which mandated Member
                  States to include basic and Surgical Care as part of Universal
                  coverage in the country. “Federal Ministry of Health produced
                  the plan and most of the implementation takes place at the
                  State level. “That is why we started the dissemination to the
                  36 States and the Federal Capital Territory after approval was
                  given at the National Council of Health,”. She said that World
                  Health Assembly Resolution 68:15 says Surgical, Obstetrics,
                  Anesthesia and Nursing care are important components of
                  Universal Health Coverage, therefore, Surgeries are fully
                  integrated into Healthcare Services.
                </p> */}

                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd6} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Sections of the Seminar
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd7} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd8} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd9} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Sections of the Seminar
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd10} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd11} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd12} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd13} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  More pictures
                </span>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'></a>
                </p>
              </div>
              {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='Lagos University Hospital World Breastfeeding Week (WBW)  | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
